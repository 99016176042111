.navigation-drawer {
  position: fixed;
  font-family: var(--font-family);

  .ant-drawer-content-wrapper {
    top: var(--header-height);
    max-height: calc(100vh - var(--header-height) * 2);
    height: auto !important;
    box-shadow: none;
  }

  .ant-drawer-body {
    max-height: calc(100vh - var(--header-height) * 2);
    padding: 0;
  }

  &__mask.ant-drawer-mask {
    top: var(--header-height);
    max-height: calc(100vh - var(--header-height));
  }
}
