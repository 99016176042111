.footerNav {
  gap: 80px;
  margin-bottom: 64px;
}

.column {
  max-width: 164px;
}

.columnTitle {
  letter-spacing: 0.02rem;
  line-height: 1.6;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom: 16px;
}

.columnItem {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemLink {
  font-style: normal;
  line-height: 1.5;
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.96);
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:hover {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.96);
  }
}
