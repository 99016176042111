.listItem {
  &:last-child {
    margin-right: 0;
  }
}

.listItemAccordion {
  &:global {
    &.ant-collapse {
      border: 0;
      background-color: transparent;

      .ant-collapse-item {
        margin-bottom: 16px;
        border: 1px solid transparent;

        &:last-child {
          margin-bottom: 0;
        }

        &.ant-collapse-item-disabled {
          border: 0;

          .ant-collapse-header {
            cursor: default;
          }
        }
      }

      .ant-collapse-expand-icon:empty {
        display: none;
      }

      .ant-collapse-header {
        align-items: center !important;
        padding: 0;
        font-family: var(--font-family);
      }

      .ant-collapse-content {
        border: 0;
      }

      .ant-collapse-content-box {
        padding: 0 0 0 12px;
        border-left: 1px solid rgb(213, 213, 213);
        margin-top: 16px;
        margin-bottom: 16px;
      }

      :local(.rootLevelLink),
      .ant-collapse-header-text {
        display: block;
        line-height: 1.57143;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.96);
      }

      :local(.rootLevelLink) {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.3s ease;

        &:hover {
          text-decoration: underline;
          text-decoration-color: rgba(0, 0, 0, 0.96);
        }
      }
    }
  }
}

.nestedLevelLink {
  font-size: 14px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.96);
  margin-bottom: 16px;
  line-height: 1.57143;
  display: block;

  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.96);
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.96);
  }
}

.listArrow {
  transition: transform 0.3s ease;

  &:global {
    &.is-open {
      transform: rotate(180deg);
    }
  }
}

.columnList {
  gap: 80px;
}

.tabColumn {
  max-width: 186px;
}

.tabColumnTitle {
  letter-spacing: 0.02em;
  line-height: 1.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.64);
  text-transform: uppercase;
  margin-bottom: 16px;
}
