.menuToggleButton {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.96);
  height: 48px;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  padding: 0 8px;
  outline: none;

  span {
    border-radius: 0;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.2s ease;

    &:hover {
      border-bottom-color: rgba(0, 0, 0, 0.96);
    }
  }
}

.closeDrawerButton {
  position: absolute;
  width: 44px;
  height: 44px;
  padding: 0;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;

  &:active {
    background-color: rgba(0, 0, 0, 0.06) !important;
    transform: scale(0.8);
  }
}

.logo {
  img {
    width: 86px;
    height: 7px;
  }
}

.drawer {
  :global {
    .ant-drawer-header {
      position: relative;
      padding: 0 16px 0 0;
      min-height: var(--header-height);
      height: var(--header-height);
    }

    .ant-drawer-body {
      padding: 0 17px 0 0;
    }
  }
}

.list {
  border: 0;
  background-color: transparent;

  &__root {
    &:global {
      &.ant-collapse {
        .ant-collapse-item {
          background-color: transparent;
          border: 0;
        }

        .ant-collapse-header {
          font-family: var(--font-family);
          padding: 16px 0;
          margin: 0 0 0 24px;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.4;
          background-color: transparent;
          color: rgba(0, 0, 0, 0.96);
          border: 0;
        }

        .ant-collapse-header-text {
          line-height: 1.4;
        }

        .ant-collapse-content {
          padding: 0;
          margin-left: 24px;
          border: 0;
        }
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  &__nested {
    &:global {
      &.ant-collapse {
        .ant-collapse-item {
          &.ant-collapse-item-disabled {
            border: 0;

            .ant-collapse-header {
              cursor: default;
            }
          }
        }

        .ant-collapse-expand-icon:empty {
          display: none;
        }

        :local(.rootLevelLink),
        .ant-collapse-header-text {
          display: block;
          line-height: 1.5;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.96);
        }

        :local(.rootLevelLink) {
          text-decoration: underline;
          text-decoration-color: transparent;
          transition: text-decoration-color 0.3s ease;

          &:hover {
            text-decoration: underline;
            text-decoration-color: rgba(0, 0, 0, 0.96);
          }
        }

        .ant-collapse-header {
          padding: 8px 0;
          margin: 0;
        }

        .ant-collapse-content {
          padding: 8px 16px 16px 16px;
          margin: 0;
        }
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
}

.nestedLevelLink {
  padding: 8px 0;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.96);
  line-height: 1.5;
  display: block;

  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.96);
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.96);
  }
}

.columnTitle {
  margin: 40px 0 8px;
  letter-spacing: 0.02em;
  line-height: 1.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: var(--font-family);
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
}

.listArrow {
  transition: transform 0.3s ease;

  &:global {
    &.is-open {
      transform: rotate(180deg);
    }
  }
}
