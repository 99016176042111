.toggleTabButton {
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.64);
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  font-family: var(--font-family);

  &:global(.is-active),
  &:hover {
    color: rgba(0, 0, 0, 0.96) !important;
    background-color: transparent !important;
    border-bottom-color: rgba(0, 0, 0, 0.96);
  }

  &:focus-visible {
    outline: 4px solid rgba(0, 0, 0, 0.15) !important;
  }
}

.closeDrawerButton {
  position: absolute;
  width: 44px;
  height: 44px;
  padding: 0;
  right: 0;
  top: 0;
  border-radius: 50%;

  &:active {
    background-color: rgba(0, 0, 0, 0.06) !important;
    transform: scale(0.8);
  }
}
