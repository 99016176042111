.wrapper {
  padding-top: var(--header-height);

  :global {
    .page-middle {
      max-width: 1200px;
      margin: 30px auto 0;
    }
  }
}
