.header {
  background-color: #fff;
  border-bottom-width: 1px;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &:global {
    & + .navigation-drawer-container {
      z-index: 9;
      position: fixed;
      top: var(--header-height);
      left: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
    }
  }
}
