@import-normalize;
@import '~bootstrap/scss/bootstrap';
@import 'fonts';
@import 'navigation-drawer';
@import 'variables';

:root {
  --header-height: 48px;
  --font-family: 'Volvo Novum', 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  :root {
    --header-height: 64px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  scrollbar-gutter: stable;
}

#root {
  min-height: 100vh;
}
