@import '../../styles/variables';

.footer {
  background-color: #fafafa;
}

.backTop {
  position: relative;
  inset-inline-end: 0;
  inset-block-end: 0;
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent !important;
  box-shadow: none;

  &:hover {
    :global {
      .ant-badge.ant-badge {
        .ant-float-btn-body {
          background-color: transparent;
        }
        .ant-float-btn-description {
          color: rgba(0, 0, 0, 0.64) !important;
        }
      }
    }
  }

  :global {
    .ant-badge.ant-badge {
      .ant-float-btn-body {
        border-radius: 0;
      }

      .ant-float-btn-description {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.96);
        font-family: var(--font-family);

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.languages {
  a {
    text-decoration: none;

    &:first-child {
      button {
        &:before {
          content: ' ';
          width: 1px;
          height: 16px;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.64);
          top: 0;
          right: -8px;
          bottom: 0;
          margin: auto;
        }
      }
    }

    &:global {
      &.is-active {
        pointer-events: none;

        button {
          border: 0;
        }
      }
    }
  }

  button {
    background-color: transparent !important;
    font-weight: 300;
    line-height: 1;
    font-size: 0.875rem;
    font-family: var(--font-family);
    color: rgba(0, 0, 0, 0.64);
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.64);
    border-radius: 0;
    height: auto;
    position: relative;

    &:hover {
      border-bottom-color: transparent;
    }

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.navLink {
  color: rgba(0, 0, 0, 0.96);
  font-size: 12px;
  font-weight: 300;
  text-decoration: none;

  &:hover {
    color: #2a609d;
  }
}

.copyright {
  font-weight: 300;
  letter-spacing: 0.02em;
  font-size: 0.75rem;
  line-height: 1.6;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.64);
  text-align: center;
}
