@font-face {
  font-display: swap;
  font-weight: 300;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-semi-light.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 300;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-semi-light.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 300;
  src: url('../../public/fonts/volvo-novum-semi-light.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-semi-light-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-semi-light-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('../../public/fonts/volvo-novum-semi-light-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-regular.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-regular.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  src: url('../../public/fonts/volvo-novum-regular.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('../../public/fonts/volvo-novum-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-medium.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-medium.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  src: url('../../public/fonts/volvo-novum-medium.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-medium-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-medium-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url('../../public/fonts/volvo-novum-medium-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-bold.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-bold.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  src: url('../../public/fonts/volvo-novum-bold.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  unicode-range: U+0370-03FF;
  src: url('../../public/fonts/volvo-novum-greek-bold-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  unicode-range: U+0400-04FF;
  src: url('../../public/fonts/volvo-novum-cyrillic-bold-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}

@font-face {
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url('../../public/fonts/volvo-novum-bold-italic.woff2') format('woff2');
  font-family: 'Volvo Novum';
}
