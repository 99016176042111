.list {
  max-width: 610px;
  margin-bottom: 64px;
  background-color: transparent;
  border: 0;

  &:global {
    &.ant-collapse {
      .ant-collapse-item {
        border: 0;
        background-color: transparent;
      }

      .ant-collapse-header {
        background-color: transparent;
        font-family: var(--font-family);
        padding: 16px 0;
        margin: 0 0 0 24px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        color: rgba(0, 0, 0, 0.96);
        border: 0;
      }

      .ant-collapse-header-text {
        line-height: 1.4;
      }

      .ant-collapse-content {
        background-color: transparent;

        padding: 0;
        margin-left: 24px;
        border: 0;
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.listInner {
  max-width: 164px;
}

.link {
  padding: 8px 0;
  font-size: 16px;
  font-weight: 300;
  font-family: var(--font-family);
  color: rgba(0, 0, 0, 0.96);
  line-height: 1.5;
  display: block;

  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.96);
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.96);
  }
}

.columnTitle {
  margin: 40px 0 8px;
  letter-spacing: 0.02em;
  line-height: 1.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: var(--font-family);
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
}

.listArrow {
  transition: transform 0.3s ease;

  &:global {
    &.is-open {
      transform: rotate(180deg);
    }
  }
}
