@import '../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0 8px;

  a {
    &:focus-visible {
      outline: 4px solid rgba(0, 0, 0, 0.15);
    }
  }

  img {
    width: 86px;
    height: 7px;
    margin-top: -3px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      height: 11px;
      width: 98px;
      margin-top: -5px;
    }
  }
}
